export const CheckerBoard = () => {
  return (
    <svg fill="none" height="44" viewBox="0 0 44 44" width="44" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_52)">
        <rect fill="white" fillOpacity="0.1" height="6" width="6" x="4" y="-2" />
        <rect fill="white" fillOpacity="0.1" height="6" width="6" x="16" y="-2" />
        <rect fill="white" fillOpacity="0.1" height="6" width="6" x="22" y="4" />
        <rect fill="white" fillOpacity="0.1" height="6" width="6" x="28" y="10" />
        <rect fill="white" fillOpacity="0.1" height="6" width="6" x="34" y="16" />
        <rect fill="white" fillOpacity="0.1" height="6" width="6" x="40" y="22" />
        <rect fill="white" fillOpacity="0.1" height="6" width="6" x="40" y="10" />
        <rect fill="white" fillOpacity="0.1" height="6" width="6" x="34" y="4" />
        <rect fill="white" fillOpacity="0.1" height="6" width="6" x="28" y="-2" />
        <rect fill="white" fillOpacity="0.1" height="6" width="6" x="40" y="-2" />
        <rect fill="white" fillOpacity="0.1" height="6" width="6" x="10" y="4" />
        <rect fill="white" fillOpacity="0.1" height="6" width="6" x="16" y="10" />
        <rect fill="white" fillOpacity="0.1" height="6" width="6" x="22" y="16" />
        <rect fill="white" fillOpacity="0.1" height="6" width="6" x="28" y="22" />
        <rect fill="white" fillOpacity="0.1" height="6" width="6" x="34" y="28" />
        <rect fill="white" fillOpacity="0.1" height="6" width="6" x="40" y="34" />
        <path d="M4 4H10V10L4 4Z" fill="black" fillOpacity="0.1" />
        <path d="M-2 -2H4V4L-2 -2Z" fill="black" fillOpacity="0.1" />
        <path d="M10 10H16V16L10 10Z" fill="black" fillOpacity="0.1" />
        <path d="M16 16H22V22L16 16Z" fill="black" fillOpacity="0.1" />
        <path d="M22 22H28V28L22 22Z" fill="black" fillOpacity="0.1" />
        <path d="M28 28H34V34L28 28Z" fill="black" fillOpacity="0.1" />
        <path d="M34 34H40V40L34 34Z" fill="black" fillOpacity="0.1" />
        <path d="M40 40H46V46L40 40Z" fill="black" fillOpacity="0.1" />
        <rect fill="black" fillOpacity="0.1" height="6" width="6" x="10" y="-2" />
        <rect fill="black" fillOpacity="0.1" height="6" width="6" x="22" y="-2" />
        <rect fill="black" fillOpacity="0.1" height="6" width="6" x="28" y="4" />
        <rect fill="black" fillOpacity="0.1" height="6" width="6" x="34" y="10" />
        <rect fill="black" fillOpacity="0.1" height="6" width="6" x="40" y="16" />
        <rect fill="black" fillOpacity="0.1" height="6" width="6" x="40" y="4" />
        <rect fill="black" fillOpacity="0.1" height="6" width="6" x="34" y="-2" />
        <rect fill="black" fillOpacity="0.1" height="6" width="6" x="16" y="4" />
        <rect fill="black" fillOpacity="0.1" height="6" width="6" x="22" y="10" />
        <rect fill="black" fillOpacity="0.1" height="6" width="6" x="28" y="16" />
        <rect fill="black" fillOpacity="0.1" height="6" width="6" x="34" y="22" />
        <rect fill="black" fillOpacity="0.1" height="6" width="6" x="40" y="28" />
      </g>
      <defs>
        <clipPath id="clip0_1_52">
          <rect fill="white" height="44" width="44" />
        </clipPath>
      </defs>
    </svg>
  );
};
