import {
  CheckIcon,
  CloseIcon,
  Heading,
  Paragraph,
  RemoveIcon,
  WarningIcon,
} from "@aidnas/design-system";
import { vercelStegaSplit } from "@vercel/stega";
import { PortableText } from "next-sanity";

import Box from "../Box";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";
import {
  AccessibilityTable as AccessibilityTableType,
  AccessibilityTableRow,
} from "@/types/sanity";

const bem = BEMHelper(styles);

const rules = {
  "1.1.1": "Relevant non-text content is described.",
  "1.3.1": "Semantic HTML is used correctly.",
  "1.3.2": "The reading order of content is logical.",
  "1.4.1": "Color is not the only way to convey information.",
  "1.4.12": "Text does not get cut off, also when zoomed.",
  "2.1.1": "All functions can be access via Keyboard navigation.",
  "2.2.2": "Focus never becomes trapped by the component.",
  "2.4.3": "Focus moves in a logical, meaningful order.",
  "2.4.4": "Each links purpose is clear in it's context.",
  "2.5.3": "The accessible name includes a visible label.",
  "3.2.1": "Content doesn't change when an element gains focus.",
  "3.2.2": "Forms do not auto-submit on input.",
  "3.3.1": "Input errors are clearly identified.",
  "3.3.2": "All inputs have labels and instructions.",
  "4.1.2": "UI components have defined names, roles, and values.",
};

export default function AccessibilityTable({ accessibilityTable }: AccessibilityTableType) {
  return (
    <Box noShadow transparent {...bem("")}>
      <table>
        <tbody>
          {accessibilityTable?.map((item) => {
            const { cleaned: guideline } = vercelStegaSplit(item.guideline || "") as {
              cleaned: AccessibilityTableRow["guideline"];
            };

            const notes = item.extraInfo ? (
              <PortableText value={item.extraInfo} />
            ) : (
              item.status === "pending" && "Test Pending"
            );

            return (
              <tr key={guideline}>
                <td>
                  {item.status === "success" && <CheckIcon size={20} />}
                  {item.status === "fail" && <CloseIcon size={20} />}
                  {item.status === "action_required" && <WarningIcon size={20} />}
                  {item.status === "pending" && <RemoveIcon size={20} />}
                </td>
                <td>
                  <Heading level={3} size="xs">
                    {item.guideline}
                  </Heading>
                </td>
                <td colSpan={item.extraInfo ? 1 : 2}>
                  {guideline && <Paragraph size="sm">{rules[guideline]}</Paragraph>}
                </td>
                {notes && <td className="">{notes}</td>}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
}
