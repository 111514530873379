import { FC } from "react";
import { Button, CopyIcon, Flex } from "@aidnas/design-system";
import { vercelStegaSplit } from "@vercel/stega";

import { PreviewProps } from "./components/types";
import { ColorPreview, ElevationPreview, RadiusPreview, SpacingPreview } from "./components";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";
import type { DesignTokens } from "@/types/sanity";

const bem = BEMHelper(styles);

const getPreview = (category: string): FC<PreviewProps> | null => {
  if (category === "color") return ColorPreview;
  if (category === "spacing") return SpacingPreview;
  if (category === "elevation") return ElevationPreview;
  if (category === "radius") return RadiusPreview;
  if (category === "gradient") return null;
  return null;
};

const formatToken = (category: string, token: string): string => {
  if (category === "spacing") return `spacing-${token}`;
  if (category === "elevation") return `elevation-${token}`;
  if (category === "radius") return `radius-${token}`;
  return token;
};

export default function DesignTokensComponent({ selection }: DesignTokens) {
  if (!selection || !selection.category || !selection.tokens) return <></>;
  const { category: rawCategory, tokens } = selection;
  const { cleaned: category } = vercelStegaSplit(rawCategory);
  const Preview = getPreview(category);

  const type =
    category === "color" &&
    tokens[0] &&
    tokens.every((token) => token.name?.startsWith(tokens[0].name?.split("-")[0] || ""))
      ? tokens[0].name?.split("-")[0]
      : undefined;

  interface FilteredTokens {
    name: string;
    description?: string;
  }

  const filteredTokens = tokens.filter((token) => token.name) as FilteredTokens[];

  if (!filteredTokens.length) return <></>;

  return (
    <div {...bem("")}>
      <Flex className={styles.tokens} fill vertical>
        {filteredTokens.map((token) => (
          <Flex
            key={`${category}-${token}`}
            align="center"
            className={styles.token}
            gap="spacing-100"
            horizontal
            justify="space-between"
          >
            <>
              {Preview && token.name && <Preview token={token.name} type={type} />}
              <div className={styles.token_name}>
                <code>{formatToken(category, token.name)}</code>
                <Button
                  aria-label="Copy token"
                  density="compact"
                  iconEnd={<CopyIcon />}
                  onClick={() => navigator.clipboard.writeText(token.name)}
                  variant="ghost"
                />
              </div>
              <div className={styles.token_description}>{token.description}</div>
            </>
          </Flex>
        ))}
      </Flex>
    </div>
  );
}
