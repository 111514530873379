import { CSSProperties, FC, ReactNode } from "react";
import { BarChartIcon } from "@aidnas/design-system";
import { vercelStegaSplit } from "@vercel/stega";
import clsx from "clsx";

import { CheckerBoard } from "./CheckerBoard";
import { PreviewProps } from "./types";

import styles from "./Styles.module.scss";

const PREVIEW_CONTENT: Record<string, ReactNode> = {
  surface: <CheckerBoard />,
  graphic: <BarChartIcon />,
  text: "Aa",
};

const PreviewElement: FC<{
  type: string;
  cleaned: string;
}> = ({ cleaned, type }) => {
  const content = PREVIEW_CONTENT[type] || null;
  const className = styles[`preview_color_${type}`] || styles.preview_color_surface;

  return (
    <div
      className={clsx(styles.preview_color, className)}
      style={{ "--token": `var(--${cleaned})` } as CSSProperties}
    >
      {content}
    </div>
  );
};

export const ColorPreview: FC<PreviewProps> = (props: PreviewProps) => {
  const { cleaned } = vercelStegaSplit(props.token || "");
  const sentiment = cleaned.includes("-on-") ? cleaned.split("-on-")[1].split("-")[0] : null;

  return (
    <div
      className={clsx(styles.wrapper, { [styles.wrapper_sentiment]: !!sentiment })}
      style={
        sentiment && props.type && props.type !== "surface"
          ? ({
              "--surface": `var(--surface-${sentiment})`,
              "--border": `var(--border-${sentiment})`,
            } as CSSProperties)
          : {}
      }
    >
      <PreviewElement cleaned={cleaned} type={props.type || "surface"} />
    </div>
  );
};
