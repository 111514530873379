import { CSSProperties, FC } from "react";
import { vercelStegaSplit } from "@vercel/stega";

import { PreviewProps } from "./types";

import styles from "./Styles.module.scss";

export const ElevationPreview: FC<PreviewProps> = (props: PreviewProps) => {
  const { cleaned } = vercelStegaSplit(props.token || "");

  return (
    <div
      className={styles.preview_elevation}
      style={{ "--elevation": `var(--elevation-${cleaned}` } as CSSProperties}
    />
  );
};
