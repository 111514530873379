import { BodyText, CheckIcon, CloseIcon, InfoIcon } from "@aidnas/design-system";
import { vercelStegaSplit } from "@vercel/stega";

import Box from "../Box";
import Image from "../Image";

import styles from "./Styles.module.scss";

import { SimplePortableText } from "@/components/PortableText";
import BEMHelper from "@/lib/bem";
import { type ImageBlock as ImageBlockType } from "@/types/sanity";

const bem = BEMHelper(styles);

export default function ImageBlock(props: ImageBlockType) {
  const { background, backgroundColor, bottomPadding, caption, title, topPadding, variant } = props;
  const { cleaned } = vercelStegaSplit(backgroundColor || "");

  const titleVariants: Record<NonNullable<ImageBlockType["variant"]>, string> = {
    do: "Do",
    dont: "Don't",
    attention: title || "Pay attention",
  };

  const renderedTitle = (variant && titleVariants[variant]) || title;
  return (
    <Box noPadding noShadow transparent {...bem("", { variant })}>
      <figure {...bem("figure")}>
        <div
          {...bem("image_wrapper", { background })}
          style={{
            backgroundColor: background
              ? `var(--${cleaned || "surface-container"})`
              : "transparent",
          }}
        >
          <Image
            classesWrapper={
              bem("image", { bottom_padding: bottomPadding, top_padding: topPadding }).className
            }
            image={props}
          />

          {variant === "dont" && (
            <svg {...bem("diagonal")} preserveAspectRatio="none" viewBox="0 0 100 100">
              <line
                stroke="var(--border-negative)"
                strokeWidth="1.4"
                vectorEffect="non-scaling-stroke"
                x1="100"
                x2="0"
                y1="0"
                y2="100"
              />
            </svg>
          )}
        </div>

        {(renderedTitle || caption) && (
          <figcaption {...bem("footer")}>
            {variant === "do" && <CheckIcon color="var(--text-on-positive)" size={20} />}
            {variant === "dont" && <CloseIcon color="var(--text-on-negative)" size={20} />}
            {variant === "attention" && <InfoIcon color="var(--text-on-attentive)" size={20} />}
            <div>
              {renderedTitle && (
                <BodyText size="sm" {...bem("title")}>
                  <strong>{renderedTitle}</strong>
                </BodyText>
              )}
              {caption && <SimplePortableText size="sm" value={caption} />}
            </div>
          </figcaption>
        )}
      </figure>
    </Box>
  );
}
